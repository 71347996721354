import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { ReactComponent as UpSortIcon } from "../../assets/icons/generic/tableSortIcons/SortUp.svg";
import { ReactComponent as DownSortIcon } from "../../assets/icons/generic/tableSortIcons/SortDown.svg";
import { ReactComponent as DefaultSortIcon } from "../../assets/icons/generic/tableSortIcons/SortDefault.svg";
import { ReactComponent as ActionCellIcon } from "../../assets/icons/generic/ActionCellIcon.svg";
import { ReactComponent as InfoIcon } from "../../assets/icons/sidebar/InfoIcon.svg";
import TagsTooltip from "../../components/linksComponents/TagsTootip";
import { useTheme } from "@table-library/react-table-library/theme";

import {
    Table,
    Header,
    HeaderRow,
    Body,
    Row,
    Cell,
    HeaderCell,
} from "@table-library/react-table-library/table";
import moment from "moment";

const SortSwitch = ({ sortKey, sort, onClick, noSort }) => {
    if (noSort) {
        return;
    }
    return (
        <SortSwitchElement onClick={onClick}>
            {sortKey === sort.sortKey ? (
                sort.ascendent ? (
                    <UpSortIcon />
                ) : (
                    <DownSortIcon />
                )
            ) : (
                <DefaultSortIcon />
            )}
        </SortSwitchElement>
    );
};

const DisplayCellBasedOnType = ({ type, value, onClick, pinLeft }) => {
    var diplayValue = value;

    if (!value && value != 0) {
        diplayValue = "-";
    }

    var display = (
        <CustomCell onClick={onClick} pinLeft={pinLeft}>
            <p>{diplayValue}</p>
        </CustomCell>
    );

    const leftContentCell = (
        <LeftContentCell onClick={onClick} pinLeft={pinLeft}>
            <p
                style={
                    type === "Name"
                        ? {
                            cursor: "pointer",
                            fontWeight: "600",
                            color: lightThemeColors.primaryColor,
                            fontSize: "14px",
                            lineHeight: "15px",
                            // minWidth: "350px",
                        }
                        : {}
                }
            >
                {diplayValue}
            </p>
        </LeftContentCell>
    );

    const actionCell = (
        <ActionCell onClick={onClick} pinLeft={pinLeft}>
            <ActionCellIcon />
        </ActionCell>
    );

    const tagsCell = (
        <ActionCell pinLeft={pinLeft}>
            <TagsTooltip value={diplayValue} />
        </ActionCell>
    );

    const coloredCell = (
        <ColoredCell onClick={onClick} pinLeft={pinLeft}>
            <p>{diplayValue}</p>
        </ColoredCell>
    );

    const dateCell = (
        <DateCell onClick={onClick} pinLeft={pinLeft}>
            <p>{diplayValue}</p>
        </DateCell>
    );

    switch (type) {
        case "Name":
            display = leftContentCell;
            break;
        case "Date":
            display = dateCell;
            break;
        case "action_cell":
            display = actionCell;
            break;
        case "tags_cell":
            display = tagsCell;
            break;
        case "colored_cell":
            display = coloredCell;
            break;

        default:
            break;
    }

    return <>{display}</>;
};

const NotificationLinksView = ({
    page,
    setPage,
    totalPages,
    setSort,
    sort,
    notificationsList,
}) => {
    const [nodes, setNodes] = useState([]);

    const createNodesFromData = (metrics) => {

        if (!metrics) {
            return;
        }

        const mapped = metrics.map((item) => {
            return {
                id: item.id,
                title: item.title,
                subtitle: item.subtitle,
                target: item.target,
                archived: item.archived,
                views: item.views,
                updated_at: moment(item.updated_at).format("MMM DD,YYYY")
            };
        });

        setNodes(mapped);
    };

    const data = { nodes };

    const COLUMNS = [
        {
            label: "Title",
            noSort: true,
            renderCell: (item) => item.title,
            type: "Name",
            background: "rgba(244, 249, 255, 1)",
            action: (item) => { },
            align: "start",
        },
        {
            label: "Subtitle",
            sortKey: "subtitle",
            renderCell: (item) => item.subtitle,
            noSort: true,
        },
        {
            label: "Views",
            sortKey: "views",
            renderCell: (item) => item.views,
            noSort: true,
        },
        {
            label: "Date",
            renderCell: (item) => item.updated_at,
            type: "Date",
            align: "end",
            noSort: true,
        },
    ];

    const theme = useTheme({
        Table: `
    --data-table-library_grid-template-columns: repeat(4, minmax(110px,1fr)) !important;
    `,
        BaseCell: `
    &:nth-of-type(1) {
      left: 0px;
    }
  `,

        HeaderRow: `
        width:100%;
        background:${lightThemeColors.menuBackground};
        border-radius:5px;
        overflow:hidden;
        `,

        HeaderCell: `
        font-size:16px;
        font-weight:700;
        color:${lightThemeColors.darkColorFaded};
        padding:14px;
       flex:inherit;
       -webkit-flex:inherit;

        :first-child{
            border-radius:5px 0 0 5px;
        }
        :last-child{
            border-radius:0 5px 5px 0;
        }
        div{
            flex:inherit;
        }
        `,

        Cell: `
    
        overflow:hidden;
        padding:14px;
        gap:20px;
        color:${lightThemeColors.darkColorFaded};
        font-size:16px;
        font-weight:${(props) => (props.lightText ? 500 : 700)};
        border-bottom:1px solid ${lightThemeColors.menuBackground};

        `,
    });



    const handleChangeSort = (sortKey) => {
        setPage(1);
        setSort({
            sortKey: sortKey,
            ascendent: !sort.ascendent,
        });
    };

    useEffect(() => {
        createNodesFromData(notificationsList);
    }, [notificationsList]);

    return (
        <Container>
            <LinksTableWrapper>
                <Table
                    data={data}
                    theme={theme}
                    columns={COLUMNS}
                    layout={{ custom: true }}
                >
                    {(tableList) => (
                        <>
                            <Header>
                                <HeaderRow>
                                    {COLUMNS.map((column, index) => (
                                        <HeaderCellCustom
                                            key={column.label}
                                            align={column.align || "center"}
                                            background={column.background}
                                            pinLeft={column.pinLeft}
                                            sortIcon={
                                                (column.type === "action_cell" ||
                                                    column.type === "tags_cell") && {
                                                    iconDefault: null,
                                                    iconUp: null,
                                                    iconDown: null,
                                                }
                                            }
                                        >
                                            {column.floatingInfo && (
                                                <>
                                                    <InfoIcon
                                                        style={{ minWidth: "13px" }}
                                                        data-tooltip-id={"audience-tooltip"}
                                                        data-tooltip-content={column.floatingInfo}
                                                    />
                                                </>
                                            )}

                                            {column.label}
                                            <SortSwitch
                                                noSort={column.noSort}
                                                sortKey={column.sortKey}
                                                sort={sort}
                                                onClick={(e) => handleChangeSort(column.sortKey)}
                                            />
                                        </HeaderCellCustom>
                                    ))}
                                </HeaderRow>
                            </Header>

                            <Body>
                                {tableList.map((item) => (
                                    <Row key={item.id} item={item}>
                                        {COLUMNS.map((column, index) => (
                                            <DisplayCellBasedOnType
                                                key={column.label + item.id}
                                                value={column.renderCell(item)}
                                                type={column.type}
                                                pinLeft={column.pinLeft}
                                                onClick={
                                                    column.action ? () => column.action(item) : () => { }
                                                }
                                            />
                                        ))}
                                    </Row>
                                ))}
                            </Body>
                        </>
                    )}
                </Table>
                <PaginationWrapper>
                    {/* <span>Total Pages: {totalPages}</span> */}
                    {totalPages > 1 && (
                        <>
                            <PaginationBtnsWrapper>
                                <PaginationBtn
                                    selected
                                    type="button"
                                    disabled={page === 1}
                                    onClick={() => setPage(page - 1)}
                                >
                                    {"<"}
                                </PaginationBtn>

                                {Array.from({ length: totalPages }).map((_, index) => (
                                    <PaginationBtn
                                        selected={index + 1 === page}
                                        key={index}
                                        type="button"
                                        onClick={() => setPage(index + 1)}
                                    >
                                        {index + 1}
                                    </PaginationBtn>
                                ))}
                                <PaginationBtn
                                    type="button"
                                    selected
                                    disabled={page === totalPages}
                                    onClick={() => setPage(page + 1)}
                                >
                                    {">"}
                                </PaginationBtn>
                            </PaginationBtnsWrapper>
                            {/* <span>Current Page: {page}</span> */}
                        </>
                    )}
                </PaginationWrapper>
            </LinksTableWrapper>
        </Container>
    );
};

export default NotificationLinksView;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
  gap: 20px;
`;

const Line = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;

const LinksTableWrapper = styled.div`
  z-index: 0;
`;

const CustomCell = styled(Cell)`
  width: 100%;
  align-items: center;
  justify-content: center;
  padding: 12px 14px;
  gap: 20px;
  border-bottom: 1px solid ${lightThemeColors.menuBackground};
  display: flex;
  margin-left: auto;
  margin-right: auto;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    color: ${lightThemeColors.links.tableCellColor};
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
  }
`;

const LeftContentCell = styled(CustomCell)`
  justify-content: start;
  margin-left: 0;
  p {
    text-align: start;
  }
`;

const ColoredCell = styled(CustomCell)`
  p {
    color: ${lightThemeColors.primaryColor};
  }
`;

const HeaderCellCustom = styled(HeaderCell)`
  background: ${(props) =>
        props.background ? props.background : "inherit"} !important;

  ${(props) =>
        props.align === "center" &&
        `div{
    display:flex;
    gap:5px;
    align-items:center;
    margin-left:auto;
    margin-right:auto;
    font-size:14px;
}`}
  ${(props) =>
        props.align === "start" &&
        `
    div{
    display:flex;
    gap:5px;
    align-items:center;
    margin-right:auto;
    font-size:14px;
}
    `}
${(props) =>
        props.align === "end" &&
        `
    div{
    display:flex;
    gap:5px;
    align-items:center;
    margin-left:auto;
    font-size:14px;
}
    `}
`;

const ActionCell = styled(CustomCell)`
  justify-content: center;
  display: flex;
  cursor: pointer;
`;

const SortSwitchElement = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PaginationWrapper = styled.div`
  padding: 30px 0;
  display: flex;
  justify-content: center;
  span {
    font-size: 16px;
    fonst-weight: 500;
    color: ${lightThemeColors.darkColor};
  }
`;

const PaginationBtn = styled.button`
  font-size: 16px;
  fonst-weight: 500;
  color: ${(props) =>
        props.selected
            ? lightThemeColors.darkColor
            : lightThemeColors.darkColorFaded30};
  background: none;
  border: none;
  cursor: pointer;
  width: 20px;
  &:disabled {
    opacity: 0.3;
  }
`;

const PaginationBtnsWrapper = styled.div`
  display: flex;
  border: 1px solid ${lightThemeColors.darkColorFaded30};
  border-radius: 5px;
  padding: 5px;
  gap: 5px;
`;

const DateCell = styled(CustomCell)`
p{
margin-left:auto;
text-align:end;
}
`