
import React from 'react'
import styled from 'styled-components'
import { lightThemeColors } from '../../assets/styles/colors'
import PrimaryButton from '../Generic/PrimaryButton'

const PreviewComponent = ({ content, ref, setShowPreview, generatedHtml, pageBackground }) => {

    return (
        <FullscreenContainer>
            <Header>
                <PrimaryButton
                    text={"Close preview"}
                    onClick={() => setShowPreview(false)}
                />
            </Header>

            <Container ref={ref} pageBackground={pageBackground}>
                <div style={{ height: "100%", width: "100%" }} dangerouslySetInnerHTML={{ __html: generatedHtml }} />
            </Container>

        </FullscreenContainer>
    )
}

export default PreviewComponent

const Container = styled.div`
display:flex;
flex-direction:column;
width:100%;
height:100%;
overflow:auto;
max-width:1440px;
margin-left:auto;
margin-right:auto;
background:${props => props.pageBackground};
`

const FullscreenContainer = styled.div`
 display: flex;
 flex-direction:column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index:4;
  background:${lightThemeColors.background};
`


const Header = styled.div`
display:flex;
align-items:center;
justify-content:center;
padding:20px;
border-bottom:1px solid ${lightThemeColors.darkColorFaded60};
`
