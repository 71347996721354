import React from 'react'
import styled from 'styled-components'
import { lightThemeColors } from '../../assets/styles/colors'

const AudienceInvited = ({ members }) => {
    return (
        <Container>
            <Header>
                <Cell ><p>UUID</p></Cell>
                <Cell ><p>Identifier</p></Cell>
                <Cell ><p>Sdk attributes</p></Cell>
            </Header>
            <List>
                {members.map((member) => (
                    <ListElement key={member.uuid}>
                        <Cell><p>{member.uuid}</p></Cell>
                        <Cell><p>{member.sdk_identifier}</p></Cell>
                        <Cell><p>{member.sdk_attributes}</p></Cell>
                    </ListElement>
                ))}
            </List>
        </Container>
    )
}

export default AudienceInvited

const Container = styled.div`
display:flex;
flex-direction:column;
width:100%;
border-top:1px solid #E7E9EC;
border-left:1px solid #E7E9EC;
border-right:1px solid #E7E9EC;
border-radius:4px;
overflow:hidden;
`
const Header = styled.div`
display:flex;
width:100%;
background:${lightThemeColors.menuBackground};
gap:5px;
border-bottom:1px solid #E7E9EC;
`
const List = styled.div`
display:flex;
flex-direction:column;
`
const ListElement = styled.div`
display:flex;
gap:5px;
border-bottom: ${props => props.header ? "" : `1px solid #E7E9EC`};
`
const Cell = styled.div`
display:flex;
align-items:center;
padding:10px 15px;
width:100%;

p{
font-size:16px;
font-weight: 600;
color:${lightThemeColors.darkColor};
text-overflow:ellipsis;
width:100%;
overflow:hidden;
}
`