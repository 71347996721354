import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";

const CustomInput = ({
  label,
  type,
  placeholder,
  value,
  onChange,
  name,
  error,
  errorText,
  disabled,
  bottomActionText,
  bottomActionClick,
  customButton,
  inputRef,
  readOnly,
  style
}) => {
  return (
    <CustomInputWrapepr >
      <Line>
        <label htmlFor={label}>{label}</label>
      </Line>
      <InputWrapper>
        <StyledInput
          type={type}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          name={name}
          disabled={disabled}
          ref={inputRef}
          readOnly={readOnly}
          style={style}
        />

        {customButton &&
          <CustomAction>
            {customButton}
          </CustomAction>
        }
      </InputWrapper>

      <BottomAction style={{ marginLeft: "auto" }} onClick={bottomActionClick}>
        {bottomActionText}{" "}
      </BottomAction>
      {error && <ErrorText>{errorText}</ErrorText>}
    </CustomInputWrapepr>
  );
};

export default CustomInput;
const CustomInputWrapepr = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  label {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: ${lightThemeColors.darkColorFaded};
  }
`;
const StyledInput = styled.input`
  padding: 13px 45px 13px 10px;

border:none;
  outline: none;
  width: 100%;
  color: ${lightThemeColors.darkColorFaded};
  border-radius: 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;

const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;
const BottomAction = styled.p`
  color: ${lightThemeColors.primaryColor};
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;
`;
const ErrorText = styled.p`
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  color: ${lightThemeColors.authentication.inputErrorColor};
`;

const CustomAction = styled.div`
display:flex;
align-items:center;
justify-content:center;
position:absolute;
right:20px;
`
const InputWrapper = styled.div`
position:relative;
display:flex;
align-items:center;
  border-radius: 5px;
gap:10px;
  background: ${lightThemeColors.background};
  border: 1px solid ${lightThemeColors.darkColorFullFaded};
`