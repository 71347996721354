import React from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { TEST } from "../../constants/OptionsConstants";
import { AnimatePresence, motion } from "framer-motion";
import { useLocation } from "react-router-dom";

const TestModeBanner = ({ projectType }) => {



  const location = useLocation();

  const displayOn = ["/links", "/configuration", "/audience"]
  const path = location.pathname;

  const isPageValid = () => {

    if (displayOn.includes(path)) {
      return true
    } else {
      return false
    }
  }


  return (
    <AnimatePresence>
      {projectType === TEST &&
        isPageValid() &&
        (
          <AnimatedDiv
            initial={{ height: 0 }}
            animate={{ height: "34px" }}
            exit={{ height: 0 }}
            transition={{ duration: 0.4 }}
          >
            <Container>
              <h1>Your're using the test environment.</h1>
            </Container>
          </AnimatedDiv>
        )}
    </AnimatePresence>
  );
};

export default TestModeBanner;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${lightThemeColors.primaryColor};
  height: 100%;
  padding: 0px;
  h1 {
    font-size: 15px;
    font-weight: 500;
    color: #fff;
  }
`;

const AnimatedDiv = styled(motion.div)``;
