import React, { useState } from 'react'
import styled from 'styled-components'
import { useTheme } from "@table-library/react-table-library/theme";

import { lightThemeColors } from '../../assets/styles/colors'
import NotificationLinksView from './MessagesLinksView'
import CustomSearchInput from '../../components/linksComponents/CustomSearchInput';
import DatePickerWithOptions from '../../components/linksComponents/DatePickerWithOptions';


const NotificationPageContent = ({
    page,
    setPage,
    notificationsList,
    sort, setSort,
    searchInputValue, handleSearchInputValue,
    startDate, setStartDate,
    endDate, setEndDate,
}) => {

    return (
        <Container>
            <Line style={{ zIndex: "2" }}>
                <CustomSearchInput
                    id="audienceSearchInput"
                    style={{ maxWidth: "300px" }}
                    value={searchInputValue}
                    setValue={handleSearchInputValue}
                    palceholder="Search"
                />
                <Line style={{ marginLeft: "auto" }}>
                    <DatePickerWithOptions
                        startDate={startDate}
                        setStartDate={setStartDate}
                        endDate={endDate}
                        setEndDate={setEndDate}
                    />

                </Line>
            </Line>

            <Separator />

            <NotificationLinksView
                notificationsList={notificationsList}
                page={page}
                setPage={setPage}
                sort={sort}
                setSort={setSort}
            />
        </Container>
    )
}

export default NotificationPageContent


const Container = styled.div`
display:flex;
flex-direction:column;
height:100%;
width:100%;

h2{
    width:100%;
}
gap:30px;
`

const Separator = styled.div`
display:flex;
height:1px;
min-height:1px;
width:100%;
background:${lightThemeColors.darkColor};
opacity:0.1;
`
const Line = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;