import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import TestProdHeader from "../../components/Generic/TestProdHeader";
import { Outlet, useOutletContext } from "react-router-dom";
import NotificationOptionLine from "./NotificationOptionLine";
import NotificationPageContent from "./NotificationPageContent";
import { useNotificationsContext } from "../../context/useNotificationsContext";
import { ALL_USERS, NEW_USERS, USERS } from "../../constants/OptionsConstants";
import { useInstanceContext } from "../../context/useInstanceContext";
import { showGenericError } from "../../helper/Notifications";
import moment from "moment";
import PrimaryButton from "../../components/Generic/PrimaryButton";
import { ReactComponent as PlusIcon } from "../../assets/icons/generic/plus.svg";
import { AnimatePresence } from "framer-motion";
import CreateNewMessageModal from "./CreateNewMessageModal";
import MessageCreator from "./MessageCreator";


const MessagesPage = () => {

  const initialSort = {
    sortKey: "title",
    ascendent: false,
  };
  const { setLoading } = useOutletContext();

  const { projectType, setProjectType } = useOutletContext();
  const { getNotifications, setNotifications } = useNotificationsContext();
  const { selectedProject } = useInstanceContext();
  const [addNewMessageModalOpen, setAddNewMessageModalOpen] = useState(false);
  const [isContentCreatorOpen, setIsContentCreatorOpen] = useState(false);

  const [generatedHtml, setGeneratedHtml] = useState(null);

  const [notificationType, setNotifificationType] = useState(ALL_USERS);
  const [notificationsList, setNotificationsList] = useState([]);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [sort, setSort] = useState(initialSort);

  const [searchInputValue, setSearchInputvalue] = useState("");
  const today = new Date()

  const initialDateStart = moment(today).subtract(29, "day").startOf("day").utc();
  const initialDateEnd = moment(today).endOf("day").utc();

  const [startDate, setStartDate] = useState(new Date(initialDateStart));
  const [endDate, setEndDate] = useState(new Date(initialDateEnd));


  const savedResultListRef = useRef([]);
  const savedContentListRef = useRef([])
  const savedPageSettingsRef = useRef()
  const savedComponentsListRef = useRef([]); // <--- used to get quill ref and access it's content to generate HTML

  const handleSearchInputValue = (inputValue) => {
    setSearchInputvalue(inputValue);
  };

  const handleGetMessages = (page, forNewUsers, archived, term, startDate, endDate) => {

    var dataObject = {
      page: page,
      for_new_users: forNewUsers,
      archived: archived,
      start_date: moment(startDate).endOf("day").toISOString(),
    }

    if (searchInputValue != "") {
      dataObject.term = term;
    }

    if (endDate) {
      dataObject.end_date = moment(endDate).endOf("day").toISOString();
    }

    setLoading(true);

    getNotifications(
      selectedProject.id,
      dataObject,
      (response) => {
        setNotificationsList(response.data.notifications);
        setTotalPages(response.data.total_pages);
        setLoading(false)
      },
      (error) => {
        showGenericError()
        setLoading(false)
      }
    )
  }


  const handleAddContentButton = () => {
    setIsContentCreatorOpen(true)
  }

  const getMessages = () => {
    if (notificationType === ALL_USERS) {
      handleGetMessages(page, false, false, searchInputValue, startDate, endDate);
    }

    if (notificationType === NEW_USERS) {
      handleGetMessages(page, true, false, searchInputValue, startDate, endDate);
    }
  }

  useEffect(() => {
    if (!selectedProject) {
      return
    }
    getMessages();
  }, [selectedProject, page, notificationType, startDate, endDate, searchInputValue])

  return (
    <Container>
      <TestProdHeader
        title={"Notifications"}
        option={projectType}
        setOption={setProjectType}
        customButton={<PrimaryButton
          icon={<PlusIcon />}
          text={"New Message"}
          onClick={() => setAddNewMessageModalOpen(true)}
        />}
      />

      <PageContent>
        <AnimatePresence>
          {addNewMessageModalOpen && (
            <CreateNewMessageModal
              isMoldaOpen={addNewMessageModalOpen}
              setIsModalOpen={setAddNewMessageModalOpen}
              handleAddContentButton={handleAddContentButton}
              generatedHtml={generatedHtml}
              setGeneratedHtml={setGeneratedHtml}
              savedResultListRef={savedResultListRef}
              savedComponentsListRef={savedComponentsListRef}
              getMessages={getMessages}
              savedContentListRef={savedContentListRef}
            />
          )}

          {isContentCreatorOpen &&
            <MessageCreator
              setIsModalOpen={setIsContentCreatorOpen}
              isMoldaOpen={isContentCreatorOpen}
              generatedHtml={generatedHtml}
              setGeneratedHtml={setGeneratedHtml}
              savedResultListRef={savedResultListRef}
              savedComponentsListRef={savedComponentsListRef}
              savedContentListRef={savedContentListRef}
              savedPageSettingsRef={savedPageSettingsRef}
            />
          }


        </AnimatePresence>
        <NotificationOptionLine notificationType={notificationType} setNotifificationType={setNotifificationType} />

        <NotificationPageContent
          page={page}
          setPage={setPage}
          totalPages={totalPages}
          notificationsList={notificationsList}
          sort={sort}
          setSort={setSort}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          searchInputValue={searchInputValue}
          handleSearchInputValue={handleSearchInputValue}
        />

      </PageContent>
    </Container >
  );
};

export default MessagesPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  gap: 0px;
  h2 {
    width: 100%;
  }
`;

const PageContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  overflow: auto;
  padding-bottom: 30px;
  padding: 0 50px;
  position: relative;
  height: 100%;
  padding-top: 20px;
`;
