import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { lightThemeColors } from '../../assets/styles/colors';
import { IMAGE, VIDEO } from '../../constants/OptionsConstants';
import ReactPlayer from 'react-player';
import { handlePosition, handleVideoSize } from '../../helper/TemplateCreatorHelpers';

const CustomVideo = ({ state, handleSelectCurrentNode, handleAddtoRefList, dropIndex }) => {
    const ref = useRef()

    const [src, setSrc] = useState(state.src);

    const [size, setSize] = useState(state.size);
    const [position, setPosition] = useState(state.position);

    useEffect(() => {
        setSrc(state.src)
        setSize(state.size)
        setPosition(state.position)

    }, [state])


    useEffect(() => {
        handleAddtoRefList(ref.current, dropIndex)
    }, []);

    return (
        <Wrapper
            onClick={() => handleSelectCurrentNode(ref.current, VIDEO)}
        >
            {src ?
                <ReactPlayer
                    controls
                    width={handleVideoSize(size)}
                    style={{
                        height: "auto",
                        marginLeft: handlePosition(position).marginLeft,
                        marginRight: handlePosition(position).marginRight
                    }}
                    url={src}
                />


                :
                <CustomPLaceholder >
                    <p>Video placeholder...</p>
                </CustomPLaceholder>
            }

        </Wrapper>
    )
}

export default CustomVideo

const Wrapper = styled.div`
display:flex;
width:100%;
padding:10px 20px;

`

const CustomPLaceholder = styled.div`
margin-left:auto;
margin-right:auto;
display:flex;
width:100%;
max-width:500px;
min-height:300px;
align-items:center;
justify-content:center;
background:${lightThemeColors.menuBackground};
color:${lightThemeColors.primaryColor};
border-radius:8px;
box-shadow: 0 2px 5px rgba(43, 58, 84, 0.2);
p{
font-size:22px;
line-height:26px;
font-weight:500;
}
`