import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import ConfigurationSectionHeader from "../configurationComponents/ConfigurationSectionHeader";

import { useExpanded } from "../../hooks/useExpanded";
import { ALL_USERS, ANDROID, IOS, NEW_USERS, WEB } from "../../constants/OptionsConstants";
import OptionSelectComponent from "../Generic/OptionSelectComponent";
import { CustomSwitch } from "../Generic/CustomSwitch";
import Select from "react-select";
import TagElement from "../Generic/TagElement";
import { parseDeliverTo } from "../../helper/TemplateCreatorHelpers";


const CreateNewMessageModalDelivery = ({
    content,
    deliverTo,
    setDeliverTo,
    noLabel,
    deliverPushNotification,
    setDeliverPushNotification,
    automaticallyDisplay,
    setAutomaticallyDisplay,
    selectedPlatforms, setSelectedPlatforms,
}) => {

    const OPTIONS_LIST = [
        {
            text: "Existing users",
            value: ALL_USERS,
        },
        {
            text: "Future Users",
            value: NEW_USERS,
        },
    ];




    const selectCustomStyles = {
        container: (provided) => ({
            ...provided,
            "&:focus-visible": {
                outline: "none",
            },
            "font-size": "14px",
        }),

        control: (provided) => ({
            ...provided,
            borderRadius: "5px",
            borderColor: lightThemeColors.darkColorFullFaded,
            outline: "none",
            "&:hover": {
                borderColor: "none",
            },
        }),
        singleValue: (provided) => ({
            ...provided,
            fontSize: "16px",
            fontWeight: "500",
            color: lightThemeColors.darkColorFaded,
        }),
        dropdownIndicator: (provided) => ({
            ...provided,
            color: lightThemeColors.darkColorFaded30,
        }),
        indicatorSeparator: (provided) => ({
            ...provided,
            display: "none",
        }),
    };

    const platformsList = [
        {
            value: IOS,
            label: "iOS",
        },
        {
            value: ANDROID,
            label: "Android",
        },
        {
            value: WEB,
            label: "Web",
        },

    ];

    const removePlatformsVersionTag = (item) => {
        let filtered = selectedPlatforms.filter(
            (metric) => metric.value !== item.value
        );
        setSelectedPlatforms(filtered);
    };



    const [expanded, expand] = useExpanded(true);

    return (
        <Container>
            <ConfigurationSectionHeader
                title={"Delivery"}
                handleExpand={expand}
                expanded={expanded}
                requiredLabel={!noLabel}
                showValidation
                isValid={selectedPlatforms.length > 0}
            />

            {expanded && (
                <ContentContainer>

                    <SubcategoryContent>
                        <ColumnGroup>
                            <Title>Deliver to</Title>
                            <Line>
                                <OptionSelectComponent
                                    optionsList={OPTIONS_LIST}
                                    selectedOption={deliverTo}
                                    setSelectedOption={setDeliverTo}
                                />
                            </Line>
                            <Warning>This message will be delivered to {parseDeliverTo(deliverTo)} one you press publish.</Warning>
                        </ColumnGroup>
                        <Separator />

                        <ColumnGroup>
                            <Line>
                                <Title>Deliver push notifications?</Title>
                                <div style={{ marginLeft: "auto" }}>
                                    <CustomSwitch
                                        option1={"No"}
                                        option2={"Yes"}
                                        selectedOption={deliverPushNotification}
                                        setSelectedOption={setDeliverPushNotification}
                                    />
                                </div>
                            </Line>
                            <InfoText>Enable this option if you want your message to be delivered as a push notification, immediately alerting the user. If disabled, the message will still be sent, but it will appear in the inbox without triggering a notification or alert.</InfoText>

                        </ColumnGroup>
                        <Separator />
                        <ColumnGroup>
                            <Line>
                                <Title>Automatically display</Title>
                                <div style={{ marginLeft: "auto" }}>
                                    <CustomSwitch
                                        option1={"No"}
                                        option2={"Yes"}
                                        selectedOption={automaticallyDisplay}
                                        setSelectedOption={setAutomaticallyDisplay}
                                    />
                                </div>
                            </Line>
                            <InfoText>Enable this option to have your message automatically displayed when the app is in use. It will appear as an overlay on the user's current screen and can be dismissed. This option is ideal for conveying urgent or important information to your users.</InfoText>

                        </ColumnGroup>
                        <Separator />
                        <ColumnGroup >
                            <Title>Platforms</Title>
                            <Select
                                isMulti
                                placeholder={"Select platforms"}
                                controlShouldRenderValue={false}
                                styles={selectCustomStyles}
                                options={platformsList}
                                isClearable={false}
                                isSearchable={false}
                                value={selectedPlatforms}
                                onChange={(e) => setSelectedPlatforms(e)}
                            />

                            <Line style={{ gap: "10px" }}>
                                {selectedPlatforms.map((platform, index) => (
                                    <TagElement
                                        key={index}
                                        tagName={platform.label}
                                        handleRemove={() => removePlatformsVersionTag(platform)}
                                    />
                                ))}
                            </Line>

                        </ColumnGroup>


                    </SubcategoryContent>
                </ContentContainer>
            )}
        </Container>
    );
};

export default CreateNewMessageModalDelivery;

const InfoText = styled.p`
  color: ${lightThemeColors.inputSubtitleColor};
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: ${lightThemeColors.background};
  width: 100%;
  gap: 10px;
`;

const ContentContainer = styled.div`
  display: flex;
//   overflow: hidden;
  flex-wrap: wrap;
  padding-bottom: 20px;
  row-gap: 20px;
`;
const SubcategoryContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  max-width: 620px;
`;

const ColumnGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
`;

const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 0px;
`;
const PreviewPlaceHolder = styled.div`
display:flex;
max-height:250px;
min-height:250px;
height:100%;
width:100%;
max-width:200px;
border:1px solid ${lightThemeColors.darkColorFullFaded};
background:${lightThemeColors.menuBackground};
border-radius:5px;
`
const Title = styled.h3`
 color: ${lightThemeColors.darkColorFaded};
    font-size: 15px;
    font-weight: 700;
    line-height: 20px;

`
const Warning = styled.div`
color: ${lightThemeColors.darkColorFaded};
font-size: 14px;
font-weight: 600;
line-height: 21px;
text-align: left;
background:rgba(251, 255, 0, 1);
padding:5px 10px;
width:fit-content;
border-radius:4px;
`
const Info = styled(Warning)`
background:${lightThemeColors.sidebar.tagBg};
color:${lightThemeColors.darkColor};
font-weight:500;
margin:auto;
`
const Separator = styled.div`
  display: flex;
  height: 1px;
  min-height: 1px;
  width: 100%;
  background: ${lightThemeColors.darkColor};
  opacity: 0.1;
`;