import React, { useEffect, useState } from "react";
import styled from "styled-components";
import SubcategoryTitleWrapper from "../../components/configurationComponents/SubcategoryTitleWrapper";
import { lightThemeColors } from "../../assets/styles/colors";
import InputFieldWithValidation from "../../components/Generic/InputFieldWithValidation";
import { ReactComponent as ComputerIcon } from "../../assets/icons/generic/DesktopIconSmall.svg";
import { isCustomUrlValid } from "../../helper/ValidatorsHelper";

const DesktopSetup = ({
  setSubtitle,
  desktopCustomUrl,
  setDesktopCustomUrl,
  setDesktopGeneratedPage,
  desktopGeneratedPage,
}) => {

  const urlOptions = [
    {
      text: "Linksquared generated page",
      value: true,
    },
    {
      text: "Custom URL",
      value: false,
    },
  ];

  const handleCheckChange = (value) => {
    setDesktopGeneratedPage(value);
  };

  const handleSetHeaderSubtitle = (e) => {
    let element = document.getElementById("desktopConfigId");
    const rect = element.getBoundingClientRect();

    if (rect.top - rect.height - 136 < 0) {
      setSubtitle("- Computers Redirect");
    }
  };

  useEffect(() => {
    let element = document.getElementById("configListId");
    element.addEventListener("scroll", handleSetHeaderSubtitle);
    return () => {
      element.removeEventListener("scroll", handleSetHeaderSubtitle);
    };
  }, []);

  return (
    <Content>
      <SubcategoryContent>
        <SubcategoryTitleWrapper
          icon={<ComputerIcon />}
          text="Computers Redirect"
          id={"desktopConfigId"}
          colorType={"faded-dark"}
        />
      </SubcategoryContent>

      <SubcategoryContent>
        <Line>
          {urlOptions.map((item, index) => (
            <RadioGroup
              key={index}
              onClick={(e) => handleCheckChange(item.value)}
            >

              <input
                type="radio"
                checked={item.value === desktopGeneratedPage}
                onChange={(e) => handleCheckChange(item.value)}
              />

              <span>{item.text}</span>
            </RadioGroup>
          ))}
        </Line>

        {desktopGeneratedPage === true && (
          <>
            {/* <Line>
              <PlaceHolderWrapper>PLACEHOLDER</PlaceHolderWrapper>
            </Line> */}
            <InfoText>
              This LinkSquared-generated page displays a QR code and direct
              download links for the app, along with your logo, when accessed
              from the web.
            </InfoText>
          </>
        )}
        {desktopGeneratedPage === false && (
          <InputFieldWithValidation
            style={{ maxWidth: "540px" }}
            placeholder={"URL to redirect to on computers (laptops, desktops)"}
            subtitle={
              "If the app is accessed from a computer, such as a laptop or desktop, links generated will redirect to the specified URL below."
            }
            labelAction={() => alert("implement")}
            inputValue={desktopCustomUrl}
            valid={desktopCustomUrl && isCustomUrlValid(desktopCustomUrl)}
            handleInputChange={(e) =>
              setDesktopCustomUrl(e.currentTarget.value.replace(/\s/g, ""))
            }
          />
        )}
      </SubcategoryContent>
    </Content>
  );
};

export default DesktopSetup;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
const Line = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 15px;
`;
const SubcategoryContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding: 0 40px;
`;

const RadioGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  cursor: pointer;

  span {
    color: ${lightThemeColors.darkColor};
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: left;

  }
    
  input {
height:15px;
width:15px;
  }
`;

const Title = styled.h1`
  color: ${lightThemeColors.darkColorFaded};
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  text-align: left;
`;

const ColumnGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
`;
const PlaceHolderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${lightThemeColors.menuBackground};
  min-width: 400px;
  min-height: 170px;
`;

const InfoText = styled.p`
  color: ${lightThemeColors.inputSubtitleColor};
  margin-top: 15px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  max-width: 500px;
`;
