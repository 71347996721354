import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import { lightThemeColors } from '../../assets/styles/colors';
import { ReactComponent as BackIcon } from "../../assets/icons/generic/SquareArrow.svg";
import { MENU, TEXT } from '../../constants/OptionsConstants';

import { parseColorFromRgbaColorPicker } from '../../helper/Utils';
import { SketchPicker } from 'react-color';
import { Item, Menu, useContextMenu } from 'react-contexify';


const CustomEditorMenu = ({
  quillRef,
  setDisplayOption,
  setActiveElementIndex,

}) => {


  const currentEditor = quillRef.getEditor();

  const [lineType, setLineType] = useState();
  const [alignType, setAlignType] = useState();
  const [textColor, setTextColor] = useState();
  const [selectionRange, setSelectionRange] = useState(null);

  const TEXT_MENU = 'text-menu'

  const { show } = useContextMenu({
    id: TEXT_MENU

  });


  function displayMenu(e) {
    // put whatever custom logic you need
    // you can even decide to not display the Menu
    show({
      event: e,
    });
  }

  const handleBack = () => {
    setDisplayOption(MENU);
    setActiveElementIndex(null)
  }

  const handleSelectedBtnStyle = (level) => {
    switch (level) {
      case 1:
        setLineType("h1")
        break;
      case 2:
        setLineType("h2")
        break;
      case 3:
        setLineType("h3")
        break;
      case false:
        setLineType("p")
        break;

      default:
        break;
    }
  }

  const handleSelectAlignType = (level) => {
    switch (level) {
      case false:
        setAlignType("left")
        break;
      case "center":
        setAlignType("center")
        break;
      case "right":
        setAlignType("right")
        break;

      default:
        break;
    }
  }

  const convertPToH = (level) => {
    if (quillRef) {
      const range = currentEditor.getSelection()
      if (range) {
        currentEditor.formatLine(range.index, 1, "header", level)
        handleSelectedBtnStyle(level)
      }
    }
  };

  const convertToP = () => {
    if (quillRef) {
      const range = currentEditor.getSelection()
      if (range) {
        currentEditor.formatLine(range.index, 1, "header", false)
        handleSelectedBtnStyle(false)
      }
    }
  };

  const alignText = (alignment) => {
    if (quillRef) {
      const range = currentEditor.getSelection()
      if (range) {
        currentEditor.formatLine(range.index, 1, "align", alignment)
        handleSelectAlignType(alignment)
      }
    }
  }

  const colorText = (color) => {

    if (quillRef && selectionRange) {
      if (selectionRange) {
        currentEditor.formatText(selectionRange.index, selectionRange.length, { color: color })
        setTextColor(color)
      }
    }
  }


  const getLineTextType = () => {
    const range = currentEditor.getSelection(); // Get current cursor position or selection range

    if (range) {
      setSelectionRange(range);
      const format = currentEditor.getFormat(range.index); // Get the format at the cursor position

      if (format.header) {
        setLineType(`h${format.header}`); // If the format is a header, set the type (h1, h2, etc.)
      } else {
        setLineType('p'); // If no header, it's a paragraph
      }

      if (format.align) {
        setAlignType(format.align)
      } else {
        setAlignType("left")
      }

      if (format.color) {
        setTextColor(format.color)
      } else {
        setTextColor(lightThemeColors.darkColor)
      }

    }

  };

  useEffect(() => {
    if (!currentEditor) {
      return
    }

    if (currentEditor) {
      // Add a click event listener to the editor
      currentEditor.root.addEventListener('click', getLineTextType);
    }
    return () => {
      if (currentEditor) {
        // Remove the event listener on unmount
        currentEditor.root.removeEventListener('click', getLineTextType);
      }
    };
  }, []);

  useEffect(() => {

    if (!currentEditor) {
      return
    }
    getLineTextType()
  }, [currentEditor])



  return (
    <MenuCard>
      <MenuContent>
        <Line >
          <CloseBtn onClick={() => handleBack()} >
            <BackIcon />
            Back
          </CloseBtn>
          <HeadWrapper>
            <h1>{TEXT}</h1>
          </HeadWrapper>
        </Line>

        <Group>
          <Line>
            <CustomMenuButton
              onClick={() => convertPToH(1)}
              selected={lineType === "h1"}
            >
              H1
            </CustomMenuButton>
            <CustomMenuButton
              onClick={() => convertPToH(2)}
              selected={lineType === "h2"}
            >
              H2
            </CustomMenuButton>
            <CustomMenuButton
              onClick={() => convertPToH(3)}
              selected={lineType === "h3"}
            >
              H3
            </CustomMenuButton>
            <CustomMenuButton onClick={() => convertToP()}
              selected={lineType === "p"}
            >
              P
            </CustomMenuButton>
          </Line>
        </Group>

        <Group>
          <GroupDescription>
            Text Align
          </GroupDescription>
          <Line>
            <CustomMenuButton onClick={() => alignText(false)}
              selected={alignType === "left"}
            >
              Left
            </CustomMenuButton>
            <CustomMenuButton onClick={() => alignText("center")}
              selected={alignType === "center"}
            >
              Center
            </CustomMenuButton>
            <CustomMenuButton onClick={() => alignText("right")}
              selected={alignType === "right"}
            >
              Right
            </CustomMenuButton>
          </Line>
        </Group>



        <Group>
          <GroupDescription>Text Color</GroupDescription>
          <Line>
            <ColorPickerContainer onClick={(e) => displayMenu(e)} >
              <ColorPickerInside style={{ background: textColor }} />
            </ColorPickerContainer>

          </Line>
        </Group>

      </MenuContent>

      <Menu id={TEXT_MENU}>
        <Item closeOnClick={false} style={{ padding: 0 }}>
          <SketchPicker
            width='300px'
            color={textColor}
            onChange={(color) => colorText(parseColorFromRgbaColorPicker(color.rgb))}
          />
        </Item>


      </Menu>

    </MenuCard>
  )
}

export default CustomEditorMenu
const MenuCard = styled.div`
max-width:600px;
width:100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items:center;
  top:0;
  background:${lightThemeColors.menuBackground};
  z-index:1;
  position:relative;


  .contexify{
    padding:0 !important;
    }
  .contexify_itemContent{
  padding:0!important;
  }
  .contexify_itemContent:hover{
  background-color:transparent!important;
  
  }

`;

const HeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin:auto;
  h1 {
    color: ${lightThemeColors.darkColorFaded};
    font-size: 25px;
    font-weight: 700;
    line-height: 37.5px;
  }
  p {
    color: ${lightThemeColors.darkColorFaded30};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
`;

const CloseBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border: none;
  background: none;
  cursor: pointer;
 gap:10px;
 color:${lightThemeColors.primaryColor};
 font-size:18px;
 line-height:22px;
 font-weight:500;
 position:absolute;
 top:40;
 left:40;
`;
const Line = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  padding: 0 40px;
  width:100%;
`;
const Group = styled.div`
display: flex;
flex-direction:column;
  gap: 10px;
  width:100%;
`

const MenuContent = styled.div`
width:100%;
display:flex;
flex-direction:column;
gap:20px;
padding: 40px 0;
`



const CustomButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  .is-active {
    background: ${lightThemeColors.primaryColor};
    color: ${lightThemeColors.background};
  }
`;


const CustomColorInput = styled.input.attrs({ type: "color" })`

width:100%;
  border: none;
  cursor: pointer;
  padding: 0;

  &::-webkit-color-swatch {
    border: none;
    padding: 0;

  }
  &::-webkit-color-swatch-wrapper {
    padding: 0;
    border: none;

  }

  &::-moz-color-swatch {
    border: none;
    padding: 0;

  }
`;


const CustomMenuButton = styled.button`
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(43, 58, 84, 0.2);
  padding: 10px;
  background-color: #fff;
  color:${lightThemeColors.darkColor};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  border: none;
  font-size:18px;
  width:100%;
  cursor: pointer;
  ${props => props.selected ? `
    background:${lightThemeColors.darkColor};
    color:${lightThemeColors.background};
    `:
    ""

  }
`;


const GroupDescription = styled.p`
  font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: ${lightThemeColors.darkColorFaded};
padding:0 40px;
`


const ColorPickerContainer = styled.div`
width:50px;
height:50px;
border-radius:50px;
border:none;
position:relative;
cursor:pointer;
padding:3px;
background:${lightThemeColors.background};
  box-shadow: 0 2px 5px rgba(43, 58, 84, 0.2);

`

const ColorPickerInside = styled.div`
display:flex;
width:100%;
height:100%;
border-radius:50px;
`