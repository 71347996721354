import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { ARCHIVE, CANCEL_PLAN, DELETE_PROJECT, DOMAIN, MEMBER, REMOVE_ACCOUNT } from "../../constants/OptionsConstants";


const DeleteConfirmationChild = ({ action, item, variant, disableButton, children, open, setOpen }) => {


    const handleClose = () => {
        setOpen(false);
    };

    const handleRemove = () => {
        action(item);
        setOpen(false);
    };


    const DisplayDialogVariant = ({ variant }) => {
        let displayDialog = (
            <DialogContentText id="alert-dialog-description">
                Are you sure you want to remove this item?
            </DialogContentText>)

        if (variant === MEMBER) {
            displayDialog = (
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to remove the access for this project for {item}
                </DialogContentText>)
        }
        if (variant === ARCHIVE) {
            displayDialog = (
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to archive this link? Archiving it will make the link inaccessible.
                </DialogContentText>)
        }
        if (variant === CANCEL_PLAN) {
            displayDialog = (
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to cancel this subscription?
                </DialogContentText>)
        }
        if (variant === REMOVE_ACCOUNT) {
            displayDialog = (
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to remove current account?
                </DialogContentText>)
        }
        if (variant === DELETE_PROJECT) {
            displayDialog = (
                <DialogContentText id="alert-dialog-description">
                    Are you sure you want to remove current project?
                </DialogContentText>)
        }
        if (variant === DOMAIN) {
            displayDialog = (
                <DialogContentText id="alert-dialog-description">
                    This will invalidate all your previous links. Changing the domain will require updating the app links as well. Be aware that previously released apps won’t be able to open the  links anymore, and developers will need to reconfigure the linksquared SDK to accommodate this change. Are you sure you want to proceed?”
                </DialogContentText>)
        }

        return displayDialog
    }

    const DisplayConfirmationButton = ({ variant }) => {
        let displayDialog = `Yes`
        if (variant === MEMBER) {
            displayDialog = `Remove`
        }
        if (variant === ARCHIVE) {
            displayDialog = `Archive`
        }
        if (variant === DOMAIN) {
            displayDialog = `Yes, change domain`
        }

        return displayDialog
    }
    return (
        (<>
            {children}
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Warning"}</DialogTitle>
                <DialogContent>
                    <DisplayDialogVariant variant={variant} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}> No</Button>
                    <Button style={{ color: "red" }} onClick={handleRemove} autoFocus>
                        <DisplayConfirmationButton variant={variant} />
                    </Button>
                </DialogActions>
            </Dialog>
        </>
        )


    );
};

export default DeleteConfirmationChild;



