import React from "react";
import styled from "styled-components";
import { REFERRALS, USERS } from "../../constants/OptionsConstants";
import OptionSelectComponent from "../../components/Generic/OptionSelectComponent";

const AudienceOptionsLine = ({ audienceType, setAudienceType, }) => {
    const OPTIONS_LIST = [
        {
            text: "Users",
            value: USERS,
        },
        {
            text: "Referrals",
            value: REFERRALS,
        },
    ];



    return (
        <Container>
            <OptionSelectComponent
                optionsList={OPTIONS_LIST}
                selectedOption={audienceType}
                setSelectedOption={setAudienceType}
            />

        </Container>
    );
};

export default AudienceOptionsLine;
const Container = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;
