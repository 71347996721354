import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components';
import { lightThemeColors } from '../../assets/styles/colors';
import { ReactComponent as BackIcon } from "../../assets/icons/generic/SquareArrow.svg";
import { BUTTON, CENTER, END, FIT, FULL, IMAGE, LARGE, MEDIUM, MENU, PILL, ROUND, SMALL, SQUARE, START, } from '../../constants/OptionsConstants';
import CustomInput from '../AuthenticationComponents/CustomInput';


const CustomEditorImageMenu = ({

    setDisplayOption,
    setActiveElementIndex,
    resultList,
    activeElementIndex,
    handleItemChanges

}) => {

    const currentImage = resultList.find((item) => item.id === activeElementIndex)
    const currentProps = currentImage.content.props.state

    const [size, setSize] = useState(currentProps?.size);
    const [position, setPosition] = useState(currentProps?.position);
    const [title, setTitle] = useState(currentProps?.title)
    const [src, setSrc] = useState(currentProps?.src)
    const [alt, setAlt] = useState(currentProps?.alt)

    const handleBack = () => {
        setDisplayOption(MENU);
        setActiveElementIndex(null)
    }


    useEffect(() => {

        if (!currentImage || !currentProps) {
            return
        }

        setSize(currentProps.size)
        setPosition(currentProps.position)
        setTitle(currentProps.title)
        setSrc(currentProps.src)
        setAlt(currentProps.alt)

    }, [currentImage.id])


    useEffect(() => {
        handleItemChanges(currentImage.id, "size", size)
    }, [size])

    useEffect(() => {
        handleItemChanges(currentImage.id, "position", position)
    }, [position])

    useEffect(() => {
        handleItemChanges(currentImage.id, "title", title)
    }, [title])
    useEffect(() => {
        handleItemChanges(currentImage.id, "src", src)
    }, [src])
    useEffect(() => {
        handleItemChanges(currentImage.id, "alt", alt)
    }, [alt])

    return (
        <MenuCard>
            <MenuContent>
                <Line >
                    <CloseBtn onClick={() => handleBack()} >
                        <BackIcon />
                        Back
                    </CloseBtn>
                    <HeadWrapper>
                        <h1>{IMAGE}</h1>
                    </HeadWrapper>
                </Line>

                <Group>
                    <GroupDescription>
                        Position
                    </GroupDescription>
                    <Line>
                        <CustomMenuButton
                            onClick={() => setPosition(START)}
                            selected={position === START}
                        >
                            Left
                        </CustomMenuButton>
                        <CustomMenuButton
                            onClick={() => setPosition(CENTER)}
                            selected={position === CENTER}
                        >
                            Center
                        </CustomMenuButton>
                        <CustomMenuButton onClick={() => setPosition(END)}
                            selected={position === END} >
                            Right
                        </CustomMenuButton>

                    </Line>
                </Group>
                <Group>
                    <GroupDescription>
                        Size
                    </GroupDescription>
                    <Line>
                        <CustomMenuButton
                            onClick={() => setSize(SMALL)}
                            selected={size === SMALL}
                        >
                            Small
                        </CustomMenuButton>
                        <CustomMenuButton
                            selected={size === MEDIUM}
                            onClick={() => setSize(MEDIUM)}
                        >
                            Medium
                        </CustomMenuButton>
                        <CustomMenuButton
                            selected={size === FULL}
                            onClick={() => setSize(FULL)} >
                            Full
                        </CustomMenuButton>

                    </Line>
                </Group>
                <Group>
                    <GroupDescription>
                        Source
                    </GroupDescription>
                    <Line>
                        <CustomInput
                            value={src}
                            onChange={(e) => setSrc(e.currentTarget.value)}
                        />

                    </Line>
                </Group>
                <Group>
                    <GroupDescription>
                        Title
                    </GroupDescription>
                    <Line>
                        <CustomInput
                            value={title}
                            onChange={(e) => setTitle(e.currentTarget.value)}
                        />

                    </Line>
                </Group>
                <Group>
                    <GroupDescription>
                        Alt
                    </GroupDescription>
                    <Line>
                        <CustomInput
                            value={alt}
                            onChange={(e) => setAlt(e.currentTarget.value)}
                        />
                    </Line>
                </Group>
            </MenuContent>

        </MenuCard>
    )
}

export default CustomEditorImageMenu
const MenuCard = styled.div`
max-width:600px;
width:100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items:center;
  top:0;
  background:${lightThemeColors.menuBackground};
  z-index:1;
  position:relative;
`;

const HeadWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin:auto;
  h1 {
    color: ${lightThemeColors.darkColorFaded};
    font-size: 25px;
    font-weight: 700;
    line-height: 37.5px;
  }
  p {
    color: ${lightThemeColors.darkColorFaded30};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
  }
`;

const CloseBtn = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  border: none;
  background: none;
  cursor: pointer;
 gap:10px;
 color:${lightThemeColors.primaryColor};
 font-size:18px;
 line-height:22px;
 font-weight:500;
 position:absolute;
 top:40;
 left:40;
`;
const Line = styled.div`
  display: flex;
  gap: 30px;
  align-items: center;
  padding: 0 40px;
  width:100%;
`;
const Group = styled.div`
display: flex;
flex-direction:column;
  gap: 10px;
  width:100%;
`

const MenuContent = styled.div`
width:100%;
display:flex;
flex-direction:column;
overflow:auto;
gap:20px;
padding: 40px 0;
`



const CustomButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  .is-active {
    background: ${lightThemeColors.primaryColor};
    color: ${lightThemeColors.background};
  }
`;


const CustomColorInput = styled.input.attrs({ type: "color" })`

width:100%;
  border: none;
  cursor: pointer;
  padding: 0;

  &::-webkit-color-swatch {
    border: none;
    padding: 0;

  }
  &::-webkit-color-swatch-wrapper {
    padding: 0;
    border: none;

  }

  &::-moz-color-swatch {
    border: none;
    padding: 0;

  }
`;


const CustomMenuButton = styled.button`
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(43, 58, 84, 0.2);
  padding: 10px;
  background-color: #fff;
  color:${lightThemeColors.darkColor};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 5px;
  border: none;
  font-size:18px;
  width:100%;
  cursor: pointer;
  ${props => props.selected ? `
    background:${lightThemeColors.darkColor};
    color:${lightThemeColors.background};
    `:
        ""
    }
`;


const GroupDescription = styled.p`
  font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: ${lightThemeColors.darkColorFaded};
padding:0 40px;
`