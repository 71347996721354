export function openInNewTab(url) {
  if (url) {
    setTimeout(() => {
      window.open(url, "_blank");
    });
  } else {
    console.error("Invalid URL provided");
  }
}


export const mapKeyPairValues = (keyPairValue) => {
  if (!keyPairValue) {
    return [];
  }
  const newData = Object.keys(keyPairValue).map((element) => ({
    key: element,
    value: keyPairValue[element],
  }));
  return newData;
};

export const parseColorFromRgbaColorPicker = (color) => {

  let returnColor = `rgba(${color.r},${color.g},${color.b},${color.a})`
  return returnColor
}