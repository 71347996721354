import React, { useState } from "react";
import styled from "styled-components";
import LoginCard from "../../components/AuthenticationComponents/LoginCard";
import { lightThemeColors } from "../../assets/styles/colors";
import LoaderComponent from "../../components/Generic/LoaderComponent";
import { ReactComponent as Logo } from "../../assets/icons/generic/LogoLong.svg";
const LoginPage = () => {
  const [loading, setLoading] = useState(false);

  const navigateToWebpage = () => {
    window.location.replace("http://linksquared.io")
  }
  return (
    <LoaderComponent loading={loading}>
      <Container>

        <LogoWrapper onClick={() => navigateToWebpage()}>
          <Logo />
        </LogoWrapper>

        <LoginCard setIsLoading={setLoading} />
      </Container>
    </LoaderComponent>
  );
};

export default LoginPage;

const Container = styled.div`
  display: flex;
  align-items: center;
  background: ${lightThemeColors.menuBackground};
  height: 100%;
  gap: 50px;
  overflow: auto;
  padding: 80px;
   @media(max-width:650px){
padding:20px 20px;
  }
`;
const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  jutify-conten: center;
  position: fixed;
  top: 40px;
  max-width: 150px;
  z-index: 0;
    cursor:pointer;

`;

