import React, { useEffect } from "react";
import styled from "styled-components";
import { lightThemeColors } from "../../assets/styles/colors";
import { ReactComponent as DownIcon } from "../../assets/icons/generic/downIcon.svg";
import { ReactComponent as ValidIcon } from "../../assets/icons/generic/validCircle.svg";
import PrimaryButton from "../Generic/PrimaryButton";
import SecondaryButton from "../Generic/SecondaryButton";

const ConfigurationSectionHeader = ({
  icon,
  title,
  expanded,
  handleExpand,
  requiredLabel,
  optionalLabel,
  showValidation,
  isValid,
  subtitle,
  customButton,
  headerStyle,
  unsavedChanges,
  customInfoText
}) => {
  useEffect(() => { }, [subtitle]);

  return (
    <ConfigHeaderBox style={headerStyle} unsavedChanges={unsavedChanges}>
      <Line onClick={handleExpand}>
        {icon && (
          <IconWrapper unsavedChanges={unsavedChanges}>{icon}</IconWrapper>
        )}
        <h1>{title} {subtitle && subtitle}</h1>
        {requiredLabel && (
          <RequiredLabel>
            <p>Required</p>
          </RequiredLabel>
        )}
        {optionalLabel && (
          <OptionalLabel>
            <p>Optional</p>
          </OptionalLabel>
        )}


        <RightSideWrapper>
          {customInfoText &&
            customInfoText
          }
          {unsavedChanges && (
            <UnsavedChangesWrapper>
              <p>You have unsaved changed</p>
            </UnsavedChangesWrapper>
          )}
        </RightSideWrapper>
      </Line>

      <ButtonsWrapper>{customButton}</ButtonsWrapper>

      <RightGroup onClick={handleExpand}>
        {showValidation && !customButton && (
          <ValidationWrapper isvalid={isValid}>
            <ValidIcon />
          </ValidationWrapper>
        )}

        <DownIconWrapper>
          <DownIcon
            style={
              expanded
                ? {
                  transform: "scaleY(-1)",
                  transition: "200ms linear",
                  cursor: "pointer",
                }
                : { cursor: "pointer" }
            }
          />
        </DownIconWrapper>
      </RightGroup>
    </ConfigHeaderBox>
  );
};

export default ConfigurationSectionHeader;

const ConfigHeaderBox = styled.div`
  display: flex;
  align-items: center;
  background: ${(props) =>
    props.unsavedChanges === true
      ? lightThemeColors.primaryColor
      : "rgba(244, 249, 255, 1)"};
  padding: 0px 16px;
  justify-content: space-between;
  border: 1px solid #e7e9ec;
  overflow: hidden;
  border-radius: 5px;
  position: sticky;
  top: 0;
  z-index: 2;
  gap: 10px;
  min-height: 40px;

  h1 {
    color: ${(props) =>
    props.unsavedChanges
      ? lightThemeColors.background
      : lightThemeColors.darkColor};
    font-size: 15px;
    font-weight: 600;
    line-height: 20px;
  }
`;
const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
  height: 100%;
  cursor: pointer;
  overflow: hidden;
`;
const RightGroup = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;
const DownIconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    color: ${lightThemeColors.darkColorFaded};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  max-height: 21px;
  max-width: 23px;
  height: 100%;
  width: 100%;
  svg {
    max-height: 21px;
    color: ${(props) =>
    props.unsavedChanges
      ? lightThemeColors.background
      : lightThemeColors.primaryColor};
  }
`;

const RequiredLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 20px;
  background: ${lightThemeColors.sidebar.requiredBg};
  border-radius: 2px;
  p {
    color: ${lightThemeColors.darkColor};
    font-size: 10px;
    font-weight: 600;
    line-height: 15px;
    text-align: left;
    text-transform: uppercase;
  }
`;
const OptionalLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 20px;
  background: ${lightThemeColors.sidebar.optionalBg};
  border-radius: 2px;
  p {
    color: ${lightThemeColors.primaryColor};
    font-size: 10px;
    font-weight: 600;
    line-height: 15px;
    text-align: left;
    text-transform: uppercase;
  }
`;
const ValidationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) =>
    props.isvalid
      ? lightThemeColors.validGreen
      : lightThemeColors.darkColorFullFaded};
`;
const ButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-right: 10px;
`;

const UnsavedChangesWrapper = styled.div`
  display: flex;
  overflow: hidden;
  p {
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
    color: ${lightThemeColors.background};
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
  }
`;
const RightSideWrapper = styled.div`
  display: flex;
  margin-left: auto;
  overflow: hidden;
  gap:10px;
`